.fixedPotInfo {
	z-index: 1;
	position: fixed;
	bottom: 10px;
	left: 10px;
	gap: 5px;
	background-image: linear-gradient(
		0deg,
		rgb(255, 219, 14) 0%,
		rgb(252, 130, 78) 100%
	);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	/* cursor: pointer; */
	-webkit-transition: all 0.1s ease;
	-moz-transition: all 0.1s ease;
	-o-transition: all 0.1s ease;
	transition: all 0.1s ease;
}

.money-bag {
	width: 60px;
}

.fixedPotInfoShown {
	opacity: 0;
	transform: translateY(100%);
	transition: opacity 0.3s ease-out, transform 0.3s ease-out;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	background-image: linear-gradient(
		0deg,
		rgb(255, 219, 14) 0%,
		rgb(252, 130, 78) 100%
	);
}

.fixedPotInfoShown.show {
	opacity: 1;
	transform: translateY(0%);
	z-index: 5;
}

.fixedPotInfoShown-wrapper {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 5;
}

.fixedPot-tools {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	padding: 0px 20px;
	margin-top: 10px;
}

.fixedPot-tools span {
	width: 30%;
	text-align: center;
	font-weight: bold;
	font-size: 1.5em;
	background-color: aliceblue;
	padding: 5px 10px;
	border-radius: 5px;
	cursor: pointer;
	-webkit-transition: all 0.1s ease;
	-moz-transition: all 0.1s ease;
	-o-transition: all 0.1s ease;
	transition: all 0.1s ease;
}

.fixedPot-tokens-wrapper {
	width: 100%;
	display: grid;
	grid-auto-rows: 50px;
	grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
	margin: 0 auto;
}

.fixedPot-token {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 1.3em;
	font-weight: bold;
	color: black;
	gap: 2px;
	width: fit-content;
	width: 120px;
	height: 50px;
}

.fixedPot-token img {
	width: 30px;
}

#fixedpot-comet-image {
	width: 40px;
}

.fixedPot-tools span:active {
	scale: 1.5;
}

.fixedPot-nowallet {
	text-align: center;
	font-weight: bold;
	font-size: 1.2em;
	padding: 0px 20px;
}

@media (max-width: 650px) {
	.fixedPotInfo {
		height: 50px;
	}
	.money-bag {
		width: 50px;
	}
}

@media (max-width: 480px) {
	.fixedPotInfo {
		height: 50px;
	}
	.money-bag {
		width: 50px;
	}

	.fixedPot-token {
		font-size: 1.2em;
	}

	.fixedPot-token img {
		width: 23px;
	}

	#fixedpot-comet-image {
		width: 30px;
	}
}

@media (max-width: 405px) {
	.fixedPotInfo {
		height: 30px;
	}
	.money-bag {
		width: 30px;
	}

	.fixedPot-token {
		font-size: 1.1em;
	}
}

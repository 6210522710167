.plinko-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: -12vh;
	height: 90vh;
	width: 100%;
	color: white;
	position: relative;
}

.risk-ballamount-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10%;
	position: absolute;
	top: 30%;
	right: 10%;
	transform: translate(-50%, -50%);
	height: 50%;
}

.current-play-plinko {
	position: absolute;
	top: 20%;
	left: 75%;
	width: 200px;
	padding: 10px;
	font-size: 1.5em;
	font-weight: 500;
}

.risk-selector-wrapper {
	display: flex;
	flex-direction: column;
	align-self: flex-start;
	gap: 3px;
}

.simulation-speed-select {
	width: 100%;
}

.row-selector-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	gap: 3px;
	position: absolute;
	top: 30%;
	right: 20%;
	transform: translate(-50%, -50%);
	height: 50%;
}

.row-selector {
	text-align: center;
	font-size: 1.1em;
	width: 25px;
	padding: 5px;
	background-color: #91e1f9;
	color: black;
	border-radius: 2px;
	font-weight: 500;
	cursor: pointer;
}

.risk-selector {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.1em;
	width: 100%;
	padding: 5px;
	color: black;
	font-weight: 500;
	height: 20%;
	border-radius: 2px;
	cursor: pointer;
}

.row-selector.chosen,
.risk-selector.chosen {
	font-size: 1.2em;
	padding: 8px;
	border: 4px solid white;
	font-weight: bold;
	border-radius: 3px;
}

.row-selector-title,
.risk-selector-title,
.ball-amount-title {
	color: white;
	font-size: 1.1em;
	font-weight: bold;
}

.gif-icon__wrapper {
	position: absolute;
	top: 40%;
	left: 20%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.gif-icon {
	opacity: 0.5;
	width: 10vw;
}

.gif-banner {
	position: absolute;
	top: 80%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 25vw;
	opacity: 0.5;
}

.play-button-plinko {
	position: absolute;
	top: 25%;
	left: 20%;
	transform: translate(-50%, -50%);
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	width: fit-content;
	padding: 10px 15px;
	background-color: #91e1f9;
	border-radius: 8px;
	font-size: 2em;
	font-weight: bold;
	color: #060725;
	border: 3px solid #91e1f9;
	cursor: pointer;
}

.play-button-plinko.disabled {
	pointer-events: none;
	opacity: 0.9;
	background-color: grey;
	border: 3px solid grey;
}

.play-button-plinko:hover {
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	color: #91e1f9;
	box-shadow: inset 3.5em 0 0 0 #060725, inset -3.5em 0 0 0 #060725;
}

.play-button-plinko:active {
	-webkit-transition: all 0.1s ease;
	-moz-transition: all 0.1s ease;
	-o-transition: all 0.1s ease;
	transition: all 0.1s ease;
	padding: 16px 24px;
}

.ball-select {
	width: 100%;
	color: black;
}

@media screen and (max-width: 550px) {
	.plinko-wrapper {
		height: 80vh;
	}

	.gif-banner {
		top: 110%;
		width: 50vw;
	}

	.gif-icon__wrapper {
		display: none;
	}

	.current-play-plinko {
		top: 90%;
		left: 60%;
		transform: translate(-50%, -50%);
	}

	.play-button-plinko {
		top: 70%;
		left: 15%;
		padding: 5px 8px;
		font-size: 1.6em;
	}

	.row-selector-wrapper {
		flex-direction: row;
		top: 70%;
		left: 45%;
	}

	.ball-amount-wrapper,
	.simulation-speed-select {
		width: 22%;
	}

	.row-selector {
		font-size: 0.9em;
		width: 20px;
		padding: 5px;
		border-radius: 2px;
	}

	.risk-ballamount-wrapper {
		flex-direction: row;
		align-items: center;
		justify-content: space-evenly;
		top: 85%;
		right: 0%;
		left: 50%;
		height: 10%;
		width: 100%;
	}

	.risk-selector-wrapper {
		flex-direction: column;
		align-self: center;
	}

	.risk-selector {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.8em;
		padding: 3px;
	}

	.row-selector.chosen,
	.risk-selector.chosen {
		font-size: 0.9em;
		padding: 4px;
		border: 4px solid white;
		border-radius: 3px;
	}

	.row-selector-title,
	.risk-selector-title,
	.ball-amount-title {
		font-size: 1em;
	}
}

@media screen and (max-width: 400px) and (max-height: 741px) {
	.plinko-wrapper {
		height: 80vh;
	}

	.play-button-plinko {
		top: 88%;
		left: 15%;
		padding: 2px 8px;
		font-size: 1.3em;
	}

	.row-selector-wrapper {
		top: 88%;
	}

	.row-selector {
		font-size: 0.8em;
		width: 18px;
		padding: 4px;
	}

	.risk-ballamount-wrapper {
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 15px;
		top: 100%;
		right: 0%;
		left: 50%;
		width: 100%;
	}

	.risk-selector-wrapper {
		flex-direction: column;
		align-self: center;
		justify-content: center;
	}

	.risk-selector {
		font-size: 0.6em;
		padding: 1px;
		width: 50px;
	}

	.row-selector.chosen,
	.risk-selector.chosen {
		font-size: 0.7em;
		padding: 2px;
		border: 2px solid white;
		border-radius: 3px;
	}

	.row-selector-title,
	.risk-selector-title,
	.ball-amount-title {
		font-size: 0.8em;
	}

	.ball-select {
		width: 80%;
		color: black;
		text-align: center;
	}
}

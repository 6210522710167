.result-popup {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 2;
	transform: translate(-50%, -50%);
	background-color: #91e1f9;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	gap: 20px;
	box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.75);
	/* For other browsers */
	box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.75);
}

.result-popup span {
	color: black;
	font-size: 2.3em;
	font-weight: bold;
}

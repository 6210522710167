.ErgoPayTransaction-wrapper {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.75);
	box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.75);
	transition: all 0.4s ease;
	z-index: 2;
	border-radius: 8px;
	padding: 20px;
	background-color: #91e1f9;
	font-size: 1.2em;
	color: black;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;
}

.ErgoPayTransaction-wrapper a {
	text-decoration: none;
	color: black;
	padding: 10px 15px;
	background-color: #1145a5;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	border-radius: 8px;
	color: white;
}

.ErgoPayTransaction-wrapper a:hover {
	box-shadow: inset 10em 0 0 0 #060725, inset -10em 0 0 0 #060725;
	color: white;
}

.one-to-ten-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 90vh;
	width: 100%;
	gap: 50px;
}

.number-selector-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 50px;
}

.number {
	font-size: 2em;
	font-weight: bold;
	padding: 5px 10px;
	border: 2px solid black;
	border-radius: 8px;
	cursor: pointer;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
	background-color: white;
}

.winning-number {
	opacity: 0;
	position: absolute;
	top: 57%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 8em;
	padding: 50px 100px;
	background-color: #91e1f9;
	border: 2px solid black;
	border-radius: 8px;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
	z-index: -1;
}

.winning-number.active {
	opacity: 1;
	z-index: 2;
}

.number.rotating {
	animation: scaleIn 7s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
}

.number.vibrating {
	animation: vibrate 0.03s linear 0s infinite;
	-moz-animation: vibrate 0.03s linear 0s infinite; /* Firefox */
	-webkit-animation: vibrate 0.03s linear 0s infinite; /* Safari and Chrome */
}

.number:hover {
	background-color: #91e1f9;
	-webkit-transform: translateY(-10px);
	transform: translateY(-10px);
	margin: 0px 5px;
}

@keyframes scaleIn {
	from {
		transform: scale(0.4, 0.4);
		opacity: 0.9;
	}
	to {
		transform: scale(2, 2);
		opacity: 0;
	}
}

@keyframes vibrate {
	0% {
		margin-left: 0px;
	}
	25% {
		margin-left: -3px;
	}
	50% {
		margin-left: 0px;
	}
	75% {
		margin-left: 3px;
	}
	100% {
		margin-left: 0px;
	}
}

@-moz-keyframes vibrate /* Firefox */ {
	0% {
		margin-left: 0px;
	}
	25% {
		margin-left: -3px;
	}
	50% {
		margin-left: 0px;
	}
	75% {
		margin-left: 3px;
	}
	100% {
		margin-left: 0px;
	}
}

@-webkit-keyframes vibrate /* Safari and Chrome */ {
	0% {
		margin-left: 0px;
	}
	25% {
		margin-left: -3px;
	}
	50% {
		margin-left: 0px;
	}
	75% {
		margin-left: 3px;
	}
	100% {
		margin-left: 0px;
	}
}

@media screen and (max-width: 950px) {
	.number-selector-wrapper {
		gap: 30px;
	}

	.number {
		font-size: 1.7em;
		font-weight: bold;
		padding: 4px 8px;
	}
}

@media screen and (max-width: 700px) {
	.number-selector-wrapper {
		gap: 20px;
	}

	.number {
		font-size: 1.5em;
		font-weight: bold;
		padding: 3px 6px;
	}
}

@media screen and (max-width: 530px) {
	.number-selector-wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.number {
		font-size: 1.5em;
		font-weight: bold;
		padding: 4px 8px;
	}
}

.firstTimeHoveringPot-modal {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 3;
	transform: translate(-50%, -50%);
	background-image: linear-gradient(
		0deg,
		rgb(255, 219, 14) 0%,
		rgb(252, 130, 78) 100%
	);
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	gap: 15px;
	box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.75);
	/* For other browsers */
	box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.75);
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	color: #060725;
	width: fit-content;
	height: fit-content;
}

.firstTimeHoveringPot-modal h1 {
	margin: 0px;
}

.firstTimeHoveringPot-modal p {
	font-size: 1.2em;
}

.firstTimeHoveringPot-modal--buttons {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 20px;
	margin-top: 12px;
}

.firstTimeHoveringPot-modal--button-confirm,
.firstTimeHoveringPot-modal--button-cancel {
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	width: fit-content;
	padding: 6px 12px;
	background-color: #2f73f0;
	border-radius: 8px;
	font-size: 1.8em;
	font-weight: bold;
	color: white;
	border: 3px solid #2f73f0;
	cursor: pointer;
}

.firstTimeHoveringPot-modal--button-cancel {
	background-color: red;
	border: 3px solid red;
}

.firstTimeHoveringPot-modal--button-confirm:hover {
	background-color: #2f73f0b8;
	border: 3px solid #2f73f0b8;
}

.firstTimeHoveringPot-modal--button-cancel:hover {
	background-color: rgba(255, 0, 0, 0.593);
	border: 3px solid rgba(255, 0, 0, 0.593);
}

@media screen and (max-width: 600px) {
	.firstTimeHoveringPot-modal {
		width: 80vw;
	}
}

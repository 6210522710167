.social-media-wrapper {
	bottom: 2%;
	right: 1%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
	z-index: 4;
	margin-bottom: 10px;
	margin-left: 15px;
}

.social-media-wrapper img {
	width: 35px;
	height: 35px;
	cursor: pointer;
}

@media screen and (max-width: 600px) {
	.social-media-wrapper img {
		width: 35px;
		height: 35px;
		cursor: pointer;
	}
}

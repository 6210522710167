.card-war-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}

.card-war--table {
	position: relative;
	background: url("../../assets/a.jpg") repeat-y;
	background-size: 100%;
	height: 90%;
	width: 60%;
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.mid-row {
	width: 100%;
	height: 100px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.back-card {
	width: 227px;
	height: 228px;
	border-radius: 8px;
	margin-left: 30px;
}

.play-button {
	padding: 40px 0px;
	width: 227px;
	text-align: center;
	background-color: #91e1f9;
	margin-right: 30px;
	border-radius: 8px;
	font-size: 2em;
	color: white;
	font-weight: bold;
	cursor: pointer;
}

.table-player,
.table-enemy {
	position: absolute;
	top: 80%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 132px;
	height: 175px;
	border: 3px solid white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
	font-family: "Comic Sans MS", "Comic Sans", cursive;
	font-size: 2em;
	text-align: center;
}

.table-enemy {
	top: 20%;
}

.enemy-card,
.player-card {
	position: absolute;
	top: 80%;
	left: 15%;
	width: 145px;
	transform: translate(-50%, -50%);
	border-radius: 8px;
	opacity: 0;
	transform: translateY(-300px) rotate(150deg);
}

.enemy-card.active,
.player-card.active {
	top: 22%;
	left: 50%;
	opacity: 1;
	transform: translate(-50%, -50%);
	transition: all 1s;
}

.player-card.active {
	top: 78%;
}

.back-card.loading {
	animation: rotate_card 3s infinite linear;
}

@keyframes rotate_card {
	0% {
		margin-left: 30px;
	}
	50% {
		margin-left: 60px;
	}
	100% {
		margin-left: 30px;
	}
}

@media screen and (max-width: 1200px) {
	.card-war--table {
		width: 80%;
	}
}

@media screen and (max-width: 800px) {
	.card-war--table {
		width: 80%;
	}

	.enemy-card,
	.player-card {
		width: 130px;
	}

	.table-player,
	.table-enemy {
		width: 120px;
		height: 150px;
		border: 3px solid white;
	}

	.back-card {
		width: 187px;
		height: 188px;
	}

	.play-button {
		padding: 25px 0px;
		width: 187px;
		font-size: 2em;
	}
}

@media screen and (max-width: 650px) {
	.card-war--table {
		width: 90%;
		height: 87%;
		margin-bottom: 30px;
	}

	.enemy-card,
	.player-card {
		width: 110px;
	}

	.table-player,
	.table-enemy {
		width: 100px;
		height: 130px;
		border: 3px solid white;
	}

	.back-card {
		width: 147px;
		height: 148px;
	}

	.play-button {
		padding: 17px 0px;
		width: 110px;
		font-size: 1.8em;
	}
}

@media screen and (max-width: 450px) {
	.card-war--table {
		width: 95%;
		height: 87%;
	}

	.enemy-card,
	.player-card {
		width: 120px;
	}

	.table-player,
	.table-enemy {
		width: 110px;
		height: 130px;
		border: 3px solid white;
	}
}

@media screen and (max-width: 450px) and (max-height: 760px) {
	.enemy-card,
	.player-card {
		width: 90px;
	}

	.table-player,
	.table-enemy {
		width: 80px;
		height: 100px;
		border: 3px solid white;
		font-size: 1.5em;
	}

	.back-card {
		width: 117px;
		height: 118px;
	}

	.play-button {
		padding: 10px 0px;
		width: 117px;
		font-size: 1.5em;
	}
}

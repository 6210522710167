.pot-modal {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 2;
	transform: translate(-50%, -50%);
	background-color: #91e1f9;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	gap: 15px;
	box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.75);
	/* For other browsers */
	box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.75);
	opacity: 0;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	color: #060725;
	z-index: -1;
	max-width: 90vw;
	min-width: 80vw;
	height: fit-content;
}

.pot-modal h2 {
	font-size: 1.4em;
}

.pot-modal.active {
	visibility: visible;
	opacity: 1;
	z-index: 0;
}

.pot-modal--inputs {
	width: 100%;
	padding: 5px 20px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: center;
	align-items: center;
}

.row {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.input-wrapper {
	width: 100%;
	height: 40px;
	border-radius: 4px;
	position: relative;
}

.input-wrapper input {
	width: 100%;
	height: 100%;
	border-radius: 4px;
	padding-left: 30%;
	font-size: 1em;
	font-weight: bold;
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box; /* Firefox, other Gecko */
	box-sizing: border-box; /* Opera/IE 8+ */
}

.input-wrapper img {
	height: 30px;
	top: 50%;
	left: 15%;
	transform: translate(-50%, -50%);
	position: absolute;
	cursor: none;
}

.pot-modal img {
	width: 100%;
	height: 100%;
	cursor: pointer;
	width: 30px;
	height: 30px;
}

.pot-modal--buttons {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 20px;
	margin-top: 12px;
}

.pot-modal--button-confirm,
.pot-modal--button-cancel {
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	width: fit-content;
	padding: 6px 12px;
	background-color: #2f73f0;
	border-radius: 8px;
	font-size: 1.3em;
	font-weight: bold;
	color: white;
	border: 3px solid #2f73f0;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pot-modal--button-cancel {
	background-color: red;
	border: 3px solid red;
}

.pot-modal--button-confirm:hover {
	background-color: #2f73f0b8;
	border: 3px solid #2f73f0b8;
}

.pot-modal--button-cancel:hover {
	background-color: rgba(255, 0, 0, 0.593);
	border: 3px solid rgba(255, 0, 0, 0.593);
}

.pot-modal--ergopay-buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 10px;
	width: 100%;
}

.pot-modal-qr-code {
	width: 25% !important;
	height: 25% !important;
}

.pot-modal-launch-wallet--text {
	text-align: center;
	padding: 7px 15px;
	border-radius: 8px;
	font-weight: bold;
	font-size: 1.3em;
	background-color: white;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	color: #1f2ec9;
	text-decoration: none;
}

.pot-modal-launch-wallet--text:hover {
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	box-shadow: inset 6em 0 0 0 #1f2ec9, inset -6em 0 0 0 #1f2ec9;
	color: white;
}

@media (max-width: 950px) {
	.pot-modal {
		width: 80%;
		height: auto;
	}

	.pot-modal--button-confirm,
	.pot-modal--button-cancel {
		padding: 5px 10px;
		font-size: 1.3em;
	}
}

@media (max-width: 700px) {
	.pot-modal {
		width: 65%;
		height: auto;
	}

	.pot-modal--inputs {
		width: 85%;
		padding: 5px 20px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		justify-content: center;
		align-items: center;
	}

	.row {
		flex-direction: column;
	}

	.input-wrapper input {
		padding-left: 20%;
	}

	.input-wrapper img {
		left: 10%;
		width: 25px;
		height: 25px;
	}

	.pot-modal h2 {
		font-size: 1em;
		margin-top: 0px;
	}

	.pot-modal--button-confirm,
	.pot-modal--button-cancel {
		width: fit-content;
		padding: 4px 8px;
		font-size: 1em;
	}
}

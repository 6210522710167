.left-menu {
	position: fixed;
	left: -55%;
	top: 0px;
	z-index: 2;
	background-color: #06082c;
	width: 20%;
	opacity: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.left-menu.active {
	left: 0px;
	height: 100%;
	opacity: 1;
}

.close-icon {
	width: 65px;
	cursor: pointer;
	z-index: 3;
}

.navigation-links-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
	margin-bottom: 30px;
	margin-top: -50px;
}

.gamblina-connections {
	width: 75%;
	margin: 0 auto;
}

.transactions-hyperlink,
.home-hyperlink,
.faq-nav,
.contact-hyperlink,
.pot-hyperlink,
.refund-hyperlink,
.gambling-hyperlink {
	color: #8dd5f1;
	font-size: 1.5em;
	text-decoration: none;
	font-weight: bold;
	cursor: pointer;
	display: block;
	text-align: center;
}

.transactions-hyperlink:hover,
.home-hyperlink:hover,
.faq-nav:hover,
.contact-hyperlink:hover,
.pot-hyperlink:hover,
.refund-hyperlink:hover,
.gambling-hyperlink:hover {
	transform: scale(1.3, 1.3);
}

.social-media-left {
	margin: 0 auto;
}

@media screen and (max-width: 1000px) {
	.left-menu {
		width: 30%;
	}
}

@media screen and (max-width: 800px) {
	.left-menu {
		width: 35%;
	}
}

@media screen and (max-width: 600px) {
	.left-menu {
		width: 45%;
	}
}

@media screen and (max-width: 420px) {
	.left-menu {
		width: 60%;
	}
}

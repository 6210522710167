.winners-table-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	gap: 50px;
	width: 99vw;
	margin: 0px 0px 100px 0px;
}

.winners-table {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow-x: auto;
	width: 100%;
	margin: 0px;
}

table {
	border-radius: 8px;
	border-spacing: 0;
	width: 90%;
	margin: 0px;
}
th {
	text-align: left;
	font-weight: bold;
	font-size: 1.7em;
	color: black;
	text-transform: uppercase;
	padding: 10px 15px;
}

thead {
	background-color: #91e1f9;
}

td {
	text-align: left;
	font-size: 1.2em;
	color: black;
	padding: 10px 15px;
	border-top: 1px solid #91e1f9;
	border-bottom: 1px solid #91e1f9;
}

#top-left-header {
	border-top-left-radius: 8px;
}

#top-right-header {
	border-top-right-radius: 8px;
}

tbody {
	background-color: white;
}

.winners-tables-header {
	color: white;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.select-winners-table {
	width: fit-content;
	margin: 5px;
}

/* tbody tr:nth-child(odd) {
	background-color: #376bc0;
}
*/

tbody tr:nth-child(even) {
	background-color: rgba(241, 236, 236, 0.499);
}

.receipt-image {
	width: 20px;
}

@media screen and (max-width: 1100px) {
	.winners-table-wrapper {
		gap: 20px;
	}
}

@media screen and (max-width: 950px) {
	.winners-table-wrapper {
		flex-direction: column;
		gap: 50px;
		align-items: center;
		justify-content: center;
	}
}
@media screen and (max-width: 450px) {
	th {
		font-size: 1.3em;
		padding: 5px 10px;
	}

	td {
		font-size: 0.9em;
		padding: 5px 10px;
	}
}

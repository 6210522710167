.plinko-results-wrapper {
	position: absolute;
	top: 35%;
	left: 10%;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	width: fit-content;
	height: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	overflow: auto;
	gap: 5px;
}

.plinko-result {
	width: 200px;
	height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	border: 1px solid #91e1f9;
	padding: 5px;
}

.plinko-result-column {
	display: flex;
	flex-direction: column;
	flex: 1;
	font-size: 0.9em;
}

.plinko-result-column span:first-child {
	font-weight: 500;
	border-bottom: 1px solid grey;
}

.refresh-plinko-results {
	padding: 2px 5px;
	background-color: #91e1f9;
	font-size: 1.1em;
	font-weight: 500;
	border-radius: 5px;
	color: black;
	cursor: pointer;
}

@media screen and (max-width: 800px) {
	.plinko-results-wrapper {
		top: 15%;
		left: 5%;
		height: 175px;
		gap: 3px;
	}

	.plinko-result {
		width: 70px;
		height: 15px;
		padding: 5px;
	}

	.plinko-result-column {
		font-size: 0.5em;
	}

	.plinko-result-column span:first-child {
		font-weight: 500;
		border-bottom: 1px solid grey;
	}

	.refresh-plinko-results {
		padding: 2px 4px;
		font-size: 0.8em;
	}
}

@media screen and (max-width: 400px) and (max-height: 741px) {
	.plinko-results-wrapper {
		height: 150px;
	}
}

.diceroll-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 90vh;
	width: 100%;
	gap: 50px;
}

.dice-selector-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 15px;
}

.dice-selector {
	width: 50px;
	cursor: pointer;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
	color: #91e1f9;
	background-color: white;
	border-radius: 8px;
}

.dice-selector:hover {
	-webkit-transform: translateY(-10px);
	transform: translateY(-10px);
	margin: 0px 5px;
	background-color: #91e1f9;
}

.play-diceroll-button {
	padding: 20px 60px;
	border-radius: 8px;
	background-color: #91e1f9;
	font-size: 2em;
	font-weight: bold;
	cursor: pointer;
}
.dice {
	position: relative;
	width: 250px;
	height: 250px;
	transform-style: preserve-3d;
	transition: 1s ease;
	transform: rotateX(-5deg) rotateY(-5deg);
}

@keyframes rolling {
	50% {
		transform: rotateX(455deg) rotateY(455deg);
	}
}

@keyframes rollingAux {
	50% {
		transform: rotateX(1355555deg) rotateY(2155555deg);
	}
}

.face {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 8px;
	border: 1px solid rgb(110, 105, 105);
	transform-style: preserve-3d;
	background-color: #91e1f9;
}

.face::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	border-radius: 20px;
	background: #f6f3f0;
	transform: translateZ(-1px);
}

.face::after {
	position: absolute;
	content: "";
	top: 50%;
	left: 50%;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background-color: black;
}

.front {
	transform: translateZ(50px) translateZ(75px);
}

.back {
	transform: rotateX(180deg) translateZ(125px);
}

.top {
	transform: rotateX(90deg) translateZ(125px);
}

.bottom {
	transform: rotateX(-90deg) translateZ(125px);
}

.right {
	transform: rotateY(90deg) translateZ(125px);
}

.left {
	transform: rotateY(-90deg) translateZ(125px);
}

.front::after {
	width: 30px;
	height: 30px;
	background-color: black;
	margin: -15px 0 0 -15px;
}

.back::after {
	margin: -35px 0 0 -30px;
	box-shadow: 40px 0, 0 25px, 40px 25px, 0 50px, 40px 50px;
}

.top::after {
	margin: -30px 0 0 -30px;
	box-shadow: 40px 40px;
}

.bottom::after {
	margin: -36px 0 0 -36px;
	box-shadow: 26px 26px, 52px 52px, 52px 0, 0 52px;
}

.right::after {
	margin: -30px 0 0 -30px;
	box-shadow: 40px 0, 0 40px, 40px 40px;
}

.left::after {
	margin: -35px 0 0 -35px;
	box-shadow: 25px 25px, 50px 50px;
}

/*
    Roll Button styles
*/

.roll {
	cursor: pointer;
	color: #b33951;
	margin-top: 60px;
	padding: 6px 12px;
	border-radius: 3px;
	font: 700 16px "Montserrat";
	border: 2px solid #b33951;
	transition: 0.4s;
}

.roll:hover {
	color: #fff;
	background: #b33951;
}

@media screen and (max-width: 418px) {
	.dice-selector-container {
		gap: 10px;
	}

	.dice-selector {
		width: 40px;
	}

	.play-diceroll-button {
		padding: 10px 40px;
		font-size: 1.6em;
	}
	.dice {
		position: relative;
		width: 200px;
		height: 200px;
		transform-style: preserve-3d;
		transition: 1s ease;
	}

	.front {
		transform: translateZ(50px) translateZ(50px);
	}

	.back {
		transform: rotateX(180deg) translateZ(100px);
	}

	.top {
		transform: rotateX(90deg) translateZ(100px);
	}

	.bottom {
		transform: rotateX(-90deg) translateZ(100px);
	}

	.right {
		transform: rotateY(90deg) translateZ(100px);
	}

	.left {
		transform: rotateY(-90deg) translateZ(100px);
	}
}

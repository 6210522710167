body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* width */
::-webkit-scrollbar {
	width: 6px;
	border-radius: 6px;
	height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: rgba(24, 22, 22, 0.882);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #91e1f9;
	border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #317a91;
}

.cyan-color {
	color: #91e1f9;
	font-weight: 600;
}

.react-select__dropdown-indicator {
	/* Set the size of the arrow */
	padding: 40px;
}

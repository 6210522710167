.roulette-info {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.75);
	box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.75);
	transition: all 0.4s ease;
	z-index: 2;
	border-radius: 8px;
	padding: 20px;
	background-color: #91e1f9;
	font-size: 1.2em;
}

.roulette-info.show {
	visibility: visible;
	opacity: 1;
}

.roulette-info.hide {
	visibility: hidden;
	opacity: 0;
}

@media screen and (max-width: 500px) {
	.roulette-info {
		width: 90%;
		font-size: 1em;
	}
}

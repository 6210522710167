.ergopayAddress-modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.75);
	box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.75);
	transition: all 0.4s ease;
	z-index: 2;
	border-radius: 8px;
	padding: 20px;
	background-color: #91e1f9;
	font-size: 1.2em;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	gap: 5px;
	align-items: center;
}

.ergopayAddress-modal.show {
	visibility: visible;
	opacity: 1;
}

.ergopayAddress-modal input {
	border-radius: 6px;
	width: 500px;
	height: 50px;
	font-size: 1.2em;
}

.ergopay-modal-buttons {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin-top: 20px;
}

.ergopay-modal-button-confirm,
.ergopay-modal-button-cancel {
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	width: fit-content;
	padding: 10px 18px;
	background-color: #2f73f0;
	border-radius: 8px;
	font-size: 2em;
	font-weight: bold;
	color: #060725;
	border: 3px solid #2f73f0;
	cursor: pointer;
}

.ergopay-modal-button-cancel {
	background-color: red;
	border: 3px solid red;
}

.ergopay-modal-button-confirm.disabled,
.ergopay-modal-button-cancel.disabled {
	pointer-events: none;
	border: 3px solid grey;
	background-color: grey;
}

.ergopay-modal-button-confirm:hover,
.ergopay-modal-button-cancel:hover {
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	color: #91e1f9;
	box-shadow: inset 3.5em 0 0 0 #060725, inset -3.5em 0 0 0 #060725;
}

@media screen and (max-width: 650px) {
	.ergopayAddress-modal h2 {
		font-size: 1.1em;
		text-align: center;
	}

	.ergopayAddress-modal input {
		border-radius: 6px;
		width: 90%;
		height: 50px;
		font-size: 1.2em;
	}

	.ergopay-modal-button-confirm,
	.ergopay-modal-button-cancel {
		padding: 6px 12px;
		font-size: 1.4em;
	}
}
